import React from "react";
import "./Header.css";

const Header = () => {
  return (
    <header className="header">
      <div className="header-content">
        <a href="https://freeweb3.com" target="_blank" rel="noreferrer" className="logo">
          <img 
            src="https://freeweb3.infura-ipfs.io/ipfs/QmPE3vX6FkhxUTWSTHr8XqaxaM1r5C7MTb3bQB59zr196M" 
            alt="Web3 Legal Engineering Logo" 
            className="header-logo"
          />
        </a>
        <nav className="nav-links">
          <a href="https://nav.evidencer.io" target="_blank" rel="noreferrer">Navigation</a>
          <a href="https://id.freeweb3.com" target="_blank" rel="noreferrer">Get Web3 ID</a>
          <a href="https://upload.freeweb3.com" target="_blank" rel="noreferrer">Upload Documents</a>
        </nav>
      </div>
    </header>
  );
};

export default Header;
