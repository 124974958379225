import React from "react";
import "./App.css";
import Branch from "./Branch";
import Header from "./components/Header";

// const Header = () => {
//   return (
//     <header className="header">
//       <h1>Evidencer Claim Management System</h1>
//     </header>
//   );
// };

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <span className="footer-long">
        ©2025 Web3 Legal Engineering - All rights reserved.
        </span>{" "}
        {/* <span className="footer-short">W3LE - All rights reserved</span> */}
        <div className="footer-links">
          <a href="/terms" className="footer-link">
            <span className="footer-text">Terms & Conditions</span>
            <span className="footer-emoji">📜</span>
          </a>
          <a href="/privacy" className="footer-link">
            <span className="footer-text">Privacy Policy</span>
            <span className="footer-emoji">🔒</span>
          </a>
        </div>
      </div>
    </footer>
  );
};

const App = () => {
  const branchesData = [
    {
      href: "https://freeweb3.infura-ipfs.io/ipfs/QmeJ9dsUVMGRvxyRPtXdnquXwNadHzfB5u3ANcfkERTFeh",
      imageSrc:
        "https://freeweb3.infura-ipfs.io/ipfs/QmeXeUirWCB84ow9tvjTEUtXtZurua8LgRmCBKZBCNnvXt",
      overlayImageSrc:
        "https://freeweb3.infura-ipfs.io/ipfs/QmemZtGNxqnqGbXJrYxL1mV3Q9pUtsNz7KPY8n5rc3mfWG",
      altText: "Main Branch 1",
      overlayAltText: "Evidencer Form 1",
    },
    {
      href: "https://freeweb3.infura-ipfs.io/ipfs/QmWiqY1pvo3oxs4X8PpzaYsmFZNnx41TyucuiDTaqdbpv7",
      imageSrc:
        "https://freeweb3.infura-ipfs.io/ipfs/QmPgbDB5cs3v88KgbaE7E4mmoa82oGznS6n1PTHyQcFjQv",
      overlayImageSrc:
        "https://freeweb3.infura-ipfs.io/ipfs/QmeBvda8hiSmjM5hNpumRRjssgJ8Rs5oQrcfTY8y94NQrz",
      altText: "Main Branch 2",
      overlayAltText: "Evidencer Form 2",
    },
    {
      href: "https://freeweb3.infura-ipfs.io/ipfs/QmZTW65PNa5SEX7ke3ZpswMeKPaC8HTqfCNsqGDucf7uCH",
      imageSrc:
        "https://freeweb3.infura-ipfs.io/ipfs/QmckLm8KmN7UoiH4N1o56XAYesV4BjYQWZqjaix3hghqwi",
      overlayImageSrc:
        "https://freeweb3.infura-ipfs.io/ipfs/QmeVDQMYn5XRCSkmu2iYfcMJb12BBJ6v7GiR76CMdeghwk",
      altText: "Main Branch 3",
      overlayAltText: "Evidencer Form 3",
    },
    {
      href: "https://freeweb3.infura-ipfs.io/ipfs/QmfB9GMT4H4hbFrfB3jejyrTs41Ks6C2TuCZGbApHNkRSg",
      imageSrc:
        "https://freeweb3.infura-ipfs.io/ipfs/QmZa1w7GNLxN66Zk2qDzHTvfmyNWQiYcv98jn8VHD3HfNe",
      overlayImageSrc:
        "https://freeweb3.infura-ipfs.io/ipfs/QmNvsv8TeQ18yD6ogzKQe8Qj5nKSUSyXBTQPhyEXqNnzFT",
      altText: "Main Branch 4",
      overlayAltText: "Evidencer Form 4",
    },
    {
      href: "https://freeweb3.infura-ipfs.io/ipfs/QmeandRv1vZgyQCWnczuads5ScXNa6NZe5ZhMDyjcDiymJ",
      imageSrc:
        "https://freeweb3.infura-ipfs.io/ipfs/QmPPL3cymygm4JXyJqGMQCwzkpVQVT4t5KLqhhCiPrCur2",
      overlayImageSrc:
        "https://freeweb3.infura-ipfs.io/ipfs/QmbZYQb1sHrwUY2s533W5EZYN34C3eWvERUB7dAo9XCZ2b",
      altText: "Main Branch 5",
      overlayAltText: "Evidencer Form 5",
    },
    {
      href: "https://freeweb3.infura-ipfs.io/ipfs/QmY7SfVBnQnhL3ET6ng6yem46D6MvChHPzEkktnHN5fta9",
      imageSrc:
        "https://freeweb3.infura-ipfs.io/ipfs/QmZvkC9RW7pDEc8Jih7jEyV57NfjLNJVobHRfxeT3Lz5Us",
      overlayImageSrc:
        "https://freeweb3.infura-ipfs.io/ipfs/Qmcj9EVc2PPxBfcBioZyzt8757Nw62ZdMM4dNFnXbNR4dD",
      altText: "Main Branch 6",
      overlayAltText: "Evidencer Form 6",
    },
    {
      href: "https://freeweb3.infura-ipfs.io/ipfs/QmeYB4JUWg1VebAWb3iYMCunxhu18kfdoDNVfmHn6v6nSf",
      imageSrc:
        "https://freeweb3.infura-ipfs.io/ipfs/QmU4zFErwUfFWRgys8XUWRZm7H3wnnvQxB2BpvXvTovRBB",
      overlayImageSrc:
        "https://freeweb3.infura-ipfs.io/ipfs/QmcjruQYDrtzb2p774eW3yUkfdUpNMSxSrLgRTX2FZGPSu",
      altText: "Main Branch 7",
      overlayAltText: "Evidencer Form 7",
    },
    {
      href: "https://freeweb3.infura-ipfs.io/ipfs/QmYUdKTRbfLz6mCfWvxypVncwneaFVZByeXPaa2tmhtCYw",
      imageSrc:
        "https://freeweb3.infura-ipfs.io/ipfs/QmUMitUghXX5z9HankjWBtKBgHgNkiWKpufQn3c6pek5M9",
      overlayImageSrc:
        "https://freeweb3.infura-ipfs.io/ipfs/QmYgLQBKNoTWGaVo72NLFQQWKQedizXnnrrMmvvtnMdUHB",
      altText: "Main Branch 8",
      overlayAltText: "Evidencer Form 8",
    },
    {
      href: "https://freeweb3.infura-ipfs.io/ipfs/QmNpBuig2QGA4fnjnG3wtd24eTfSGaZUyKhdxRdBxoz4m8",
      imageSrc:
        "https://freeweb3.infura-ipfs.io/ipfs/QmeMDUVtbeEtBet89YUnBbgqmfg6cScZeZAdjJvUKmJemh",
      overlayImageSrc:
        "https://freeweb3.infura-ipfs.io/ipfs/QmYQYxhoDU2ZsTwkfJbVFNjk8FsH2y8EyLLsYApvvw6PLH",
      altText: "Main Branch 9",
      overlayAltText: "Evidencer Form 9",
    },
    {
      href: "https://freeweb3.infura-ipfs.io/ipfs/QmTRwPNPoC3pe1VaYCDVZLcAxzpbb8SGTctdM6CekNrhc5",
      imageSrc:
        "https://freeweb3.infura-ipfs.io/ipfs/QmSfciBxXjywdHFEa5P2Z7oqZoU4VhbUpmiKcwyph2Qd5A",
      overlayImageSrc:
        "https://freeweb3.infura-ipfs.io/ipfs/QmPXFtNkhwh19LoUFaCJ6a4bY8PMFhocHtyUYfkSX6q7ja",
      altText: "Main Branch 10",
      overlayAltText: "Evidencer Form 10",
    },
    {
      href: "https://freeweb3.infura-ipfs.io/ipfs/QmWtiSS18vKpw79Mt8du8LA56aWn4bReWASnvsE7VxyyTP",
      imageSrc:
        "https://freeweb3.infura-ipfs.io/ipfs/QmP2nC2Eto4E3ZHdqzjtYnPNeZNsroQqx1Z3kAWtwEQ3SK",
      overlayImageSrc:
        "https://freeweb3.infura-ipfs.io/ipfs/QmUoyYfrSzzBCfH2hfBwSAPF3qEbr3kFUYzSZ2qsDAEUoo",
      altText: "Main Branch 11",
      overlayAltText: "Evidencer Form 11",
    },
    {
      href: "https://freeweb3.infura-ipfs.io/ipfs/QmdnXRbDfZdLhFRbYJQcouHAuvYZjuboMtjRr7maVyTWsb",
      imageSrc:
        "https://freeweb3.infura-ipfs.io/ipfs/QmTnLw5A58ztWgEo8H3tcE7qAtVQxPAP9ncAVFRzJ327oj",
      overlayImageSrc:
        "https://freeweb3.infura-ipfs.io/ipfs/QmSezRiH8TBBdN1QfT8JJP76dBmJe1LPgMVjsqY5o672bd",
      altText: "Main Branch 12",
      overlayAltText: "Evidencer Form 12",
    },
  ];

  return (
    <div className="container">
      <Header />

      <h1>Evidencer Claim Management System</h1>
      {/* Video */}
      <div className="video-container">
        <video controls>
          <source
            src="https://freeweb3.infura-ipfs.io/ipfs/QmVMSNt1QfYd3wbhSV9mbAy5AWLGUrjgFWwzjK7jXta3Fz"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>

      <div className="container">
        <div className="grid-container">
          {branchesData.map((branch, index) => (
            <Branch
              key={index}
              href={branch.href}
              imageSrc={branch.imageSrc}
              overlayImageSrc={branch.overlayImageSrc}
              altText={branch.altText}
              overlayAltText={branch.overlayAltText}
            />
          ))}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default App;
